
.c_app_menu--header {
    display: flex;
    align-items: center;
    height: 10.5vh;
    text-align: center;
    box-shadow: 0 0 8px -5px var(--black);

    .c_app_menu--title {
        position: relative;
        top: 0;
        display: flex;
        place-content: center;
        margin: 0 auto;
        padding: 0;
        width: fit-content;
        font-size: 1.2em;
    }

    .button-md,
    .button-ios {
        all: initial;
    }

    .c_app_menu--btn-close {
        position: relative;
        top: 0.4em;
        right: 0.6em;
        text-align: center;
        font-size: 1.9em;
        background-color: transparent;

        .c_app_menu--btn-close-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            content: url(/./assets/icon/baseline-close-24px.svg);
        }
    }
}

.c_app_menu--list {
    & > .item-block {
        margin-top: 9px;
        & .item-inner,
        &:first-child,
        &:last-child {
            border: none;
        }

        &:last-child::before {
            border-bottom: none;
        }
    }
}

.c_app_menu--item {
    --color-menu-item-border: rgba(0, 0, 0, 0.16);
    --border-color: transparent;
    display: flex;
    flex-direction: column;
    place-content: space-around;
    height: 3.3em;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 90%;
        border-bottom: 1px solid var(--color-menu-item-border);
    }

    &.item-md::before {
        left: 0;
        width: 100%;
    }

    .item-inner {
        position: relative;
        bottom: 0.4em;
    }

    .c_app_menu--icon {
        vertical-align: text-bottom;
        &-add_patient { content: url(/./assets/icon/baseline-person_add-24px.svg); }
        &-support { content: url(/./assets/icon/baseline-phone_in_talk-24px.svg); }
        &-logout { content: url(/./assets/icon/baseline-exit_to_app-24px.svg); }
    }
}

.app-version {
    position: absolute;
    bottom: 1em;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 0.9em;
}
