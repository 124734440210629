@import '../../../theme/variables';

.video-page-btn-close {
    font-size: 1.5em;
}

.callDetails {
    --background: var(--color-accent-light);

    .scroll-content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    #videoCallPublisher {
        position: absolute;
        z-index: 1000;
        width: 25%;
        bottom: 10px;
        right: 10px;
    }

    #videoCallSubscriber {
        z-index: 990;
    }

    .calleeName {
        width: 100%;
        margin-top: 10%;
        text-align: center;
        font-size: 1.8rem;
        color: var(--color-accent);
    }

    .callStatus {
        position: absolute;
        color: var(--color-accent);
        font-weight: bold;
        z-index: 1000;
        min-width: 100%;
        text-align: center;
    }
}
