/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./app/progress-chart/progress-chart.page";
@import "./app/app.component";
@import "./app/patient/patient.page";
@import "./app/communication/video/video.page";
@import "./app/communication/voice/voice.page";

// Custom CSS and variables
@import "./theme/variables";
@import '../../../libs/pattern-library/common-styles';
@import url(../../../libs/pattern-library/custom-properties.scss);

:root {
    @include hrs-custom-properties;
}

ion-toolbar {
    --ion-safe-area-bottom: 2.5vh;
    --ion-safe-area-top: 4vh;
    --ion-toolbar-color: var(--black);
    ion-menu-button {
        --color: var(--black);
    }
}

// Toast Message Stylings
.signup-toast {
    // allows multi-line messages
    white-space: pre-line;
}

/* ######################################################################################### %
                                      ICON PATHS FOR PATTERN LIBRARY
% ######################################################################################### */
.c_app_header--icon-back { content: url(/assets/icon/icon-arrow_back-black.svg); }
.c_footer--icon-back { content: url(/assets/icon/icon-arrow_back-white.svg); }
.c_chat--icon-send { content: url(/assets/icon/icon-send_email.svg); }
