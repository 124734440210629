app-progress-chart {
  .progress-outer {
    width: 100%;
    --ion-background-color: var(--gray-1);
    border: 1px solid var(--gray-2);
    color: var(--white);
    border-radius: 20px;
  }

  .progress-inner {
    min-width: 1%;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px;
    border-radius: 20px;
    background-color: var(--color-primary);
  }
}
