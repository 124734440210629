@import '../../theme/variables';
@import '../../../../../libs/pattern-library/utility-mixins';

// remove default ionic borders and shadows for flat design
.card {
    border: none;
    box-shadow: none;
    background: none;
}

.patientprofile-header {
    background-color: var(--white);
    .patientprofile-title {
        text-align: center;
        font-size: 1.1em;
        margin-bottom: 0.5em;
    }
}

.section-divider {
    width: 50vw;
    margin: auto;
    border-bottom: 2px solid var(--gray-1);
}

.patientprofile-date {
    --background: var(--white);;
    margin: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--gray-1);
    box-shadow: none;

    .header-title {
        margin: 0;
        padding: 16px 16px 0 16px;
        font-weight: bold;
        color: var(--color-accent);
        font-size: 1.2em;
    }
    &-text {
        margin-top: 0;
        font-size: 1.6em;
        color: var(--color-accent);
        padding: 10px 16px;
    }
}

.patientprofile-content {
    background-color: var(--white);
}

.patientprofile-metrics {
    margin: 0;

    .patientprofile-list {
        margin-top: 1em;

        .patientprofile-metric_card {
            --border-color: transparent;
            color: var(--color-accent);
            width: 100vw;
            height: 13vh;
            margin: 0;
            background: none;
            // removes bottom border of last item in ionic lists
            border: none !important;

            .patientprofile-metric_info {

                .patientprofile-metric_title {
                    display: inline-block;
                    font-size: 1.1rem;
                    padding: 0;
                    margin: 0;
                    color: var(--color-accent);
                    text-indent: .3em;
                    text-transform: capitalize;
                }

                .patientprofile-risk_badge {
                    display: inline-block;
                    height: .6em;
                    width: .6em;
                    border-radius: 20px;
                    margin-left: .5em;
                    &-high { background: var(--color-error); }
                    &-med { background: var(--color-risk-medium); }
                    &-low { background: var(--color-success); }
                }
            }

            .patientprofile-metric_chart {
                align-items: center;

                .patientprofile-metric_completion {
                    display: flex;
                    justify-content: center;

                    .patientprofile-metric_percentage {
                        color: var(--color-accent);
                        float: right;
                        font-weight: bold;
                        font-size: 1em;
                        margin-top: 0.6em;
                        text-align: center;
                    }

                    .patientprofile-metric_icon {
                        float: right;
                        font-size: 1.4em;
                        font-weight: bold;
                        color: var(--color-accent);
                    }
                }
            }
        }
    }
}

.patientprofile-footer {
    background-color: var(--white);
    .patientprofile-communications {
        text-align: center;

        &-icon {
            @include u_ion-override-host-background-color;
            --override-background-color: var(--white);
            @include u_ion-override-host-text-color;
            --override-text-color: var(--color-primary);
            --padding-start: 0;
            --padding-end: 0;
            font-size: 1.6em;
            width: 2.9em;
        }
    }
}

