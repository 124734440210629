@import '../../../theme/variables';

.voice-page-btn-close {
    font-size: 1.5em;
}


.callDetails {
    background-color: var(--color-accent-light);

    .scroll-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: hidden;
    }

    .calleeName {
        width: 100%;
        margin-top: 10%;
        text-align: center;
        font-size: 2.5rem;
        color: var(--color-accent);
    }

    .callStatus {
        color: var(--color-accent);
        font-weight: bold;
        z-index: 1000;
        min-width: 100%;
        text-align: center;
    }

    .image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-top: 20px;
        position: absolute;
        top: 100px;
        &.preactivated {
            background: var(--color-risk-none);
        }
        &.highRisk {
            background-color: var(--color-error);
        }
        &.medRisk {
            background-color: var(--color-risk-medium);
        }
        &.lowRisk {
            background-color: var(--color-success);
        }
        img {
            margin: 0 auto;
            width: 100%;
            height: 100%;
        }
    }

    ion-avatar {
        display: flex;
        justify-content: center;
    }
}

.callButtons {
    background-color: var(--white);
    padding-bottom: 2vh;
    .row {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;
    }
    .button {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: var(--color-primary);
        text-align: center;
        .dial {
            color: var(--white);
        }

        .mute, .speaker {
            color: var(--color-primary);
        }

        .dial, .mute {
            font-size: 30px;
        }

        .speaker {
            font-size: 40px;
        }
    }

    .settings {
        background-color: transparent;
        border: 1px solid var(--color-primary);
    }

    .endCall {
        background-color: var(--color-error);
    }
}
